import { combineReducers } from 'redux';

const INITIAL_LANG = 'en';
const INITIAL_SERIAL_ID = '';

const serialDetectReducer = (serialID = INITIAL_SERIAL_ID, action) => {
    if (action.type === 'SERIAL_DETECT') {
        return action.payload;
    }
    return serialID;
};

const languageSelectedReducer = (lang = INITIAL_LANG, action) => {
    if (action.type ==='LANGUAGE_SELECT') {
        return action.payload;
    }
    return lang;
}

export default combineReducers ({
    detectedSerial: serialDetectReducer,
    selectedLanguage: languageSelectedReducer
});