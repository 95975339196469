import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router";
import { UAParser } from "ua-parser-js";
import CookieConsent from "react-cookie-consent";

import "./Footer.scss";
import apiInfor from "../../data/api.js";

class Footer extends React.Component {
  handleToPolicyPage = () => {
    this.props.history.push("/bc/policy");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  injectGA = () => {
    if (typeof window == 'undefined') {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", apiInfor.gaCode);
  };


  appPart(os) {
    const androidApp =
      "https://play.google.com/store/apps/details?id=jp.co.mti.sakenomy";
    const iosApp = "https://itunes.apple.com/app/id917958935";

    if (os === "android") {
      return (
        <Grid item xs={6}>
          <div className="item-box">
            <Button href={androidApp}>
              <img
                className="sakenomi-icon"
                src={require("../../assets/google_play.png")}
                alt="Application icon"
              />
            </Button>
          </div>
        </Grid>
      );
    } else if (os === "ios") {
      return (
        <Grid item xs={6}>
          <div className="item-box">
            <Button href={iosApp}>
              <img
                className="sakenomi-icon"
                src={require("../../assets/appstore_logo.png")}
                alt="Application icon"
              />
            </Button>
          </div>
        </Grid>
      );
    } else {
      return (
        <div className="row app-icon-box">
          <Grid item xs={6}>
            <div className="item-box">
              <Button href={iosApp}>
                <img
                  className="sakenomi-icon"
                  src={require("../../assets/appstore_logo.png")}
                  alt="Application icon"
                />
              </Button>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="item-box">
              <Button href={androidApp}>
                <img
                  className="sakenomi-icon"
                  src={require("../../assets/google_play.png")}
                  alt="Application icon"
                />
              </Button>
            </div>
          </Grid>
        </div>
      );
    }
  }

  render() {

    const sakeIcon = require("../../assets/SakenomyLogo_nocopy_C.jpg");
    const text = this.props.text;
    const downloadText = text.downloadToday;
    const keepNoteText = text.keepALog;
    const privacyText = text.privacyPolicyTitle;
    const expandText = text.expandYourSakeWorldWith;

    const uaParser = new UAParser();
    // const isSp = uaParser.getDevice();
    const os = uaParser.getOS().name.toLowerCase();

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className="mt-4 mb-4"
      >
        <Grid item xs={12}>
          <div className="item-box">
            <p className="color-gold expand-your">{expandText}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <img className="sakenomi-icon" src={sakeIcon} alt="SakeNomiIcon" />
        </Grid>
        <Grid item xs={12}>
          <div className="item-box">
            <hr />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="item-box">
            <h3>{downloadText}</h3>
          </div>
        </Grid>

        {this.appPart(os)}

        <Grid item xs={12}>
          <div className="text-center mx-4 gray mt-4">
            <p>{keepNoteText}</p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="text-center black mt-4">
            <p onClick={this.handleToPolicyPage}>{privacyText}</p>
          </div>
        </Grid>

        <CookieConsent
          location="bottom"
          buttonText="I AGREE"
          declineButtonText="Click here to decline"
          cookieName="CookieConsent"
          enableDeclineButton
          onDecline={this.handleCookieDecline}
          onAccept={this.injectGA} 
          style={{
            background: "#22274B",
            borderRadius: "20px",
            height: "auto",
            width: "100%",
            justifyContent: "center",
            justifyItems: "center",
            display: "grid"
          }}
          buttonStyle={{
            background: "#dbb36e",
            width: "100%",
            fontSize: "1.1rem",
            color: "#fff",
            cursor: "pointer",
            padding: "5% 20%",
            borderRadius: "10px",
            textTransform: "uppercase",
            textAlign: "center",
            margin: "0 0 15px 0",
          }}
          declineButtonStyle={{
            background: "none",
            margin: "0 0 15px 0",
            textAlign: "center",
            width: "100%",
            cursor: "pointer",
            color: "#1976d2",
          }}
          contentStyle={{
            flex: "1 0 300px",
            textAlign: "center",
            justifyContent: "center",
            float: "center",
            color: "#fff",
            margin: "15px 0 0 0",
          }}
          buttonWrapperClasses="cookie-button-wrapper"
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
      </Grid>
    );
  }
}

export default withRouter(Footer);
