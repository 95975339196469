import React from "react";
import Grid from "@material-ui/core/Grid";

const Foods = (props) => {
  const { brand, lang, text } = props;
  const title = text.servingSuggestionsTitle;
  const background = require("../../assets/Images/Serving Suggestions_Black Label_gradient.png");
  const subTitle1 = text.reccomendedFoodPairingTitle;
  const subTitle2 = text.raccomandedTemperatureAndGlassTitle;

  let subText1 = "Loading";
  let subText2 = "Loading";


  switch (lang) {
    case "en": {
      subText1 = brand.paringFood ? brand.enParingFood : "Loading";
      subText2 = brand.servingSuggestion ? brand.enServingSuggestion : "Loading";
      break;
    }
    case "jp": {
      subText1 = brand.paringFood ? brand.paringFood : "Loading";
      subText2 = brand.servingSuggestion ? brand.servingSuggestion : "Loading";
      break;
    }
    case "cn": {
      subText1 = brand.paringFood ? brand.cnParingFood : "Loading";
      subText2 = brand.servingSuggestion ? brand.cnServingSuggestion : "Loading";
      break;
    }
    case "hk": {
      subText1 = brand.paringFood ? brand.hkParingFood : "Loading";
      subText2 = brand.servingSuggestion ? brand.hkServingSuggestion : "Loading";
      break;
    }
    case "kr": {
      subText1 = brand.paringFood ? brand.krParingFood : "Loading";
      subText2 = brand.servingSuggestion ? brand.krServingSuggestion : "Loading";
      break;
    }
    case "th": {
      subText1 = brand.paringFood ? brand.thParingFood : "Loading";
      subText2 = brand.servingSuggestion ? brand.thServingSuggestion : "Loading";
      break;
    }
    case "vi": {
      subText1 = brand.paringFood ? brand.viParingFood : "Loading";
      subText2 = brand.servingSuggestion ? brand.viServingSuggestion : "Loading";
      break;
    }
    default: {
      subText1 = brand.paringFood ? brand.paringFood : "Loading";
      subText2 = brand.servingSuggestion ? brand.servingSuggestion : "Loading";
      break;
    }
  }

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <img
          className="img-section img-fluid"
          src={background}
          alt="SectionImage"
        />
      </Grid>

      <div className="title-text-box text-center ">
        <h2 className="title_over text-box">{title}</h2>
      </div>

      <div className="detail-content-box text-left detail-content-text">
        <p className="details-context-sub-title">{subTitle1}</p>
        <p className="mb4">{subText1}</p>
        <p className="details-context-sub-title">{subTitle2}</p>
        <p className="mb4">{subText2}</p>
      </div>
    </Grid>
  );
};

export default Foods;
