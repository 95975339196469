import React from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

const Specification = (props) => {
  const { brand, lang , text} = props;
  const title = text.sakeSpecificationsTitle;
  const background = require("../../assets/Sake_Specifications_gradient.png");
  const riceIcon = require("../../assets/icon/rice.png");
  const bottleIcon = require("../../assets/icon/bottle.png");
  const glassIcon = require("../../assets/icon/glass.png");

  let riceInfo = "Loading";
  let bottleInfo = "Loading";
  let glassInfo = "Loading";
  let sakeDesc = "Loading";

  

  switch (lang) {
    case "en": {
      riceInfo = brand.id ? brand.enRiceSpecific : "Loading";
      bottleInfo = brand.id ? brand.enBottleSize : "Loading";
      glassInfo = brand.id ? brand.enAlcohol : "Loading";
      sakeDesc = brand.id ? brand.enDescription : "Loading";
      break;
    }
    case "jp": {
      riceInfo = brand.id ? brand.riceSpecific : "Loading";
      bottleInfo = brand.id ? brand.bottleSize : "Loading";
      glassInfo = brand.id ? brand.alcohol : "Loading";
      sakeDesc = brand.id ? brand.description : "Loading";
      break;
    }
    case "cn": {
      riceInfo = brand.id ? brand.cnRiceSpecific : "Loading";
      bottleInfo = brand.id ? brand.cnBottleSize : "Loading";
      glassInfo = brand.id ? brand.cnAlcohol : "Loading";
      sakeDesc = brand.id ? brand.cnDescription : "Loading";
      break;
    }
    case "hk": {
      riceInfo = brand.id ? brand.hkRiceSpecific : "Loading";
      bottleInfo = brand.id ? brand.hkBottleSize : "Loading";
      glassInfo = brand.id ? brand.hkAlcohol : "Loading";
      sakeDesc = brand.id ? brand.hkDescription : "Loading";
      break;
    }
    case "kr": {
      riceInfo = brand.id ? brand.krRiceSpecific : "Loading";
      bottleInfo = brand.id ? brand.krBottleSize : "Loading";
      glassInfo = brand.id ? brand.krAlcohol : "Loading";
      sakeDesc = brand.id ? brand.krDescription : "Loading";
      break;
    }
    case "th": {
      riceInfo = brand.id ? brand.thRiceSpecific : "Loading";
      bottleInfo = brand.id ? brand.thBottleSize : "Loading";
      glassInfo = brand.id ? brand.thAlcohol : "Loading";
      sakeDesc = brand.id ? brand.thDescription : "Loading";
      break;
    }
    case "vi": {
      riceInfo = brand.id ? brand.viRiceSpecific : "Loading";
      bottleInfo = brand.id ? brand.viBottleSize : "Loading";
      glassInfo = brand.id ? brand.viAlcohol : "Loading";
      sakeDesc = brand.id ? brand.viDescription : "Loading";
      break;
    }
    default: {
      riceInfo = brand.id ? brand.riceSpecific : "Loading";
      bottleInfo = brand.id ? brand.bottleSize : "Loading";
      glassInfo = brand.id ? brand.alcohol : "Loading";
      sakeDesc = brand.id ? brand.description : "Loading";
      break;
    }
  }

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <img
          className="img-section img-fluid"
          src={background}
          alt="SectionImage"
        />
      </Grid>

      <div className="title-text-box text-center ">
        <h2 className="title_over text-box">{title}</h2>
      </div>
      <Hidden smUp>
        <div className="mt-4 mb-4 detail-content-text">
          <Grid container justify="center" alignContent="center">
            <Grid item xs={12} sm={4}>
              <div className="row mx-0 pt-2 pb-2">
                <div className="text-center avatar-box">
                  <img className="avatar" src={riceIcon} alt="RiceIcon" />
                </div>
                <div className="sake-text-box px-1">
                  <span className="">{riceInfo}</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className="row mx-0 pt-2 pb-2">
                <div className="text-center avatar-box">
                  <img className="avatar" src={bottleIcon} alt="RiceIcon" />
                </div>
                <div className="sake-text-box px-1">
                  <span className="sake-text-content">{bottleInfo}</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className="row mx-0 pt-2 pb-2">
                <div className="text-center avatar-box">
                  <img className="avatar" src={glassIcon} alt="RiceIcon" />
                </div>
                <div className="sake-text-box px-1">
                  <span className="sake-text-content">{glassInfo}</span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Hidden>
      <Hidden xsDown>
        <Grid
          container
          justify="center"
          alignContent="center"
          className="mt-4 mb-4"
        >
          <Grid item sm={4}>
            <Grid item>
              <div className="text-center avatar-box">
                <img className="avatar" src={riceIcon} alt="BottleIcon" />
              </div>
            </Grid>
            <Grid item sm={12}></Grid>
            <Grid item className="mt-2">
              <div className="text-center px-1">
                <span>{riceInfo}</span>
              </div>
            </Grid>
          </Grid>
          <Grid item sm={4}>
            <Grid item>
              <div className="text-center avatar-box">
                <img className="avatar" src={bottleIcon} alt="BottleIcon" />
              </div>
            </Grid>
            <Grid item sm={12}></Grid>
            <Grid item className="mt-2">
              <div className="text-center  px-1">
                <span>{bottleInfo}</span>
              </div>
            </Grid>
          </Grid>
          <Grid item sm={4}>
            <Grid item>
              <div className="text-center avatar-box">
                <img className="avatar" src={glassIcon} alt="BottleIcon" />
              </div>
            </Grid>
            <Grid item sm={12}></Grid>
            <Grid item className="mt-2">
              <div className="text-center  px-1">
                <span>{glassInfo}</span>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <div className="text-left detail-content-text mx-4">
        <p className="mb4">{sakeDesc}</p>
      </div>
    </Grid>
  );
};

export default Specification;
