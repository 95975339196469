import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { detectSerial } from "./redux/actions";

import Top from "./Top/Top";
import DetailsZone from "./DetailsZone/DetailsZone";
import MasterBrewer from "./MasterBrewer/MasterBrewer";
import Footer from "./Footer/Footer";
import apiInfor from "../data/api.js";

import "./MainApp.scss";

class MainApp extends Component {
  state = { brandData: {}, location: {} };

  componentDidMount(props) {
    const serialID = this.props.match.params.serialID;
    const apiBrand =
      apiInfor.apiUrl + apiInfor.brandAPI + apiInfor.paramSerial + serialID;

    if (serialID) this.props.detectSerial(serialID);

    axios
      .get(apiBrand)
      .then((response) => {
        this.setState({ brandData: response.data });
        this.setState({ location: response.data.location });
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }
  render() {
    let brand = this.state.brandData;
    let loc = this.state.location;
    let { lang, siteText } = this.props;
    let text = {};
    switch (lang) {
      case "en": {
        text = siteText.en;
        break;
      }
      case "jp": {
        text = siteText.jp;
        break;
      }
      case "cn": {
        text = siteText.cn;
        break;
      }
      case "hk": {
        text = siteText.hk;
        break;
      }
      case "kr": {
        text = siteText.kr;
        break;
      }
      case "th": {
        text = siteText.th;
        break;
      }
      case "vi": {
        text = siteText.vi;
        break;
      }
      default: {
        text = siteText.jp;
        break;
      }
    }

    return (
      <React.Fragment>
        <div className="main-container">
          <div className="main-part">
            <Top brand={brand} loc={loc} lang={lang} text={text} />
            <DetailsZone brand={brand} loc={loc} lang={lang} text={text} />
            <MasterBrewer loc={loc} lang={lang} text={text} />
            <Footer text={text} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    detectedSerial: state.detectedSerial,
    // selectedLanguage: state.selectedLanguage
  };
};

export default connect(mapStateToProps, { detectSerial },)(MainApp);

