import React from "react";
import Grid from "@material-ui/core/Grid";

const TastingNote = (props) => {
  const { brand, lang , text} = props;
  const title = text.tastingNotesTitle;
  const background = require("../../assets/Tasting Notes_gradient.png");
  let tastingDesc1 = "Loading";
  let tastingDesc2 = "Loading";

  

  switch (lang) {
    case "en": {
      tastingDesc1 = brand.id ? brand.enTastingNote1 : "Loading";
      tastingDesc2 = brand.id ? brand.enTastingNote2 : "Loading";
      break;
    }
    case "jp": {
      tastingDesc1 = brand.id ? brand.tastingNote1 : "Loading";
      tastingDesc2 = brand.id ? brand.tastingNote2 : "Loading";
      break;
    }
    case "cn": {
      tastingDesc1 = brand.id ? brand.cnTastingNote1 : "Loading";
      tastingDesc2 = brand.id ? brand.cnTastingNote2 : "Loading";
      break;
    }
    case "hk": {
      tastingDesc1 = brand.id ? brand.hkTastingNote1 : "Loading";
      tastingDesc2 = brand.id ? brand.hkTastingNote2 : "Loading";
      break;
    }
    case "kr": {
      tastingDesc1 = brand.id ? brand.krTastingNote1 : "Loading";
      tastingDesc2 = brand.id ? brand.krTastingNote2 : "Loading";
      break;
    }
    case "th": {
      tastingDesc1 = brand.id ? brand.thTastingNote1 : "Loading";
      tastingDesc2 = brand.id ? brand.thTastingNote2 : "Loading";
      break;
    }
    case "vi": {
      tastingDesc1 = brand.id ? brand.viTastingNote1 : "Loading";
      tastingDesc2 = brand.id ? brand.viTastingNote2 : "Loading";
      break;
    }
    default: {
      tastingDesc1 = brand.id ? brand.tastingNote1 : "Loading";
      tastingDesc2 = brand.id ? brand.tastingNote2 : "Loading";
      break;
    }
  }

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <img
          className="img-section img-fluid"
          src={background}
          alt="SectionImage"
        />
      </Grid>

      <div className="title-text-box text-center ">
        <h2 className="title_over text-box">{title}</h2>
      </div>

      <div className="detail-content-box text-left detail-content-text">
        <p className="mb4">{tastingDesc1}</p>
        <p className="mb4">{tastingDesc2}</p>
      </div>
    </Grid>
  );
};

export default TastingNote;
