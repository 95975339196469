import React from "react";
import Grid from "@material-ui/core/Grid";

const BreweryInfo = (props) => {
  const { loc, lang, text } = props;
  const title = text.breweryInformationTitle;
  const background = require("../../assets/Brewery_Information_gradient.png");
  let breweryInfo = "Loading";

  switch (lang) {
    case "en": {
      breweryInfo = loc.id ? loc.enInformation : "Loading";
      break;
    }
    case "jp": {
      breweryInfo = loc.id ? loc.information : "Loading";
      break;
    }
    case "cn": {
      breweryInfo = loc.id ? loc.cnInformation : "Loading";
      break;
    }
    case "hk": {
      breweryInfo = loc.id ? loc.hkInformation : "Loading";
      break;
    }
    case "kr": {
      breweryInfo = loc.id ? loc.krInformation : "Loading";
      break;
    }
    case "th": {
      breweryInfo = loc.id ? loc.thInformation : "Loading";
      break;
    }
    case "vi": {
      breweryInfo = loc.id ? loc.viInformation : "Loading";
      break;
    }
    default: {
      breweryInfo = loc.id ? loc.information : "Loading";
      break;
    }
  }

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <img
          className="img-section img-fluid"
          src={background}
          alt="SectionImage"
        />
      </Grid>

      <div className="title-text-box text-center ">
        <h2 className="title_over text-box">{title}</h2>
      </div>

      <div className="detail-content-box text-left detail-content-text">
        <p className="mb-4">{breweryInfo}</p>
      </div>
    </Grid>
  );
};

export default BreweryInfo;
