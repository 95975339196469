import React, { Component } from "react";

// import DetailsPart from '../DetailsPart/DetailsPart';

import TastingNote from "./TastingNote";
import Foods from "./Foods";
import Specification from "./Specifications";
import Brewery from "./BreweryInfo";

import "./DetailsZone.scss";

class DetailsZone extends Component {
  render() {
    const { brand, loc, lang, text } = this.props;

    return (
      <div>
        <TastingNote brand={brand} lang={lang} text={text} />
        <Foods brand={brand} lang={lang} text={text} />
        <Specification brand={brand} lang={lang} text={text} />
        <Brewery loc={loc} lang={lang} text={text} />
      </div>
    );
  }
}

export default DetailsZone;
