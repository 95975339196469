import React, { Component } from "react";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";

import "./NavBar.scss";

class NavBar extends Component {
  state = { barShow: true };

  closeNav = () => {
    this.setState({ barShow: false });
  };

  render() {
    const navAnchor = "top";
    const sakenomiText = "Expand your sake world with Sakenomy";
    const logo = require("../../assets/Logo/Sakenomy/notext@2x.png");
    const sakenomiLink = "https://www.sakenomy.jp/";

    return (
      <Drawer
        anchor={navAnchor}
        variant="persistent"
        open={this.state.barShow}
        className="modal-top"
      >
        <div className="row mx-0 align-items-center">
          <div className="col-1 px-0">
            <Button onClick={this.closeNav} className="text-left">
              X
            </Button>
          </div>
          <div className="col-2 px-0 text-center">
            <Button href={sakenomiLink} target="_blank">
              <img className="logo-top" src={logo} alt="NotextIcon" />
            </Button>
          </div>
          <div className="col-6 px-0 text-left">
            <span className="">{sakenomiText}</span>
          </div>
          <div className="col-2 px-0">
            <Button
              href={sakenomiLink}
              target="_blank"
              variant="outlined"
              color="primary"
            >
              View
            </Button>
          </div>
        </div>
      </Drawer>
    );
  }
}

export default NavBar;
