import React from "react";
import Grid from "@material-ui/core/Grid";

import "./MasterBrewer.scss";

const MasterBrewer = (props) => {
  const masterIcon = require("../../assets/icon/master.png");

  const { loc, lang, text } = props;
  const masterText = text.masterBrewerTitle;
  let masterName = "Loading";

  switch (lang) {
    case "en": {
      masterName = loc.id ? loc.enDescription : "Loading";
      break;
    }
    case "jp": {
      masterName = loc.id ? loc.description : "Loading";
      break;
    }
    case "cn": {
      masterName = loc.id ? loc.cnDescription : "Loading";
      break;
    }
    case "hk": {
      masterName = loc.id ? loc.hkDescription : "Loading";
      break;
    }
    case "kr": {
      masterName = loc.id ? loc.krDescription : "Loading";
      break;
    }
    case "th": {
      masterName = loc.id ? loc.thDescription : "Loading";
      break;
    }
    case "vi": {
      masterName = loc.id ? loc.viDescription : "Loading";
      break;
    }
    default: {
      masterName = loc.id ? loc.description : "Loading";
      break;
    }
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className="border-top-bottom"
    >
      <Grid item xs={2} sm={1}>
        <div className="item-box">
          <img className="mt4 img-master" src={masterIcon} alt="mastericon" />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="item-box">
          <h3 className="master_brewer_title">{masterText}</h3>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="item-box">
          <p>{masterName}</p>
        </div>
      </Grid>
    </Grid>
  );
};

export default MasterBrewer;
