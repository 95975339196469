import React from "react";
import Grid from "@material-ui/core/Grid";

import "./Header.scss";

const Header = () => {
  const topPic = require("../../assets/SakenomyLogo_landscape.jpg");

  return (
      <Grid item xs={6}>
      <div className="item-box">
        <img className="img-hero img-fluid" src={topPic} alt="TopLogo" />
        </div>
      </Grid>
  );
}

export default Header;