export const detectSerial = (serial) => {
    //return an action
    return {
        type: 'SERIAL_DETECT',
        payload: serial
    };
};

export const selectLanguage = (lang) => {
    return {
        type: 'LANGUAGE_SELECT',
        payload: lang
    }
}