import React, { Component } from "react";
import { connect } from "react-redux";
import { selectLanguage } from "../redux/actions";

import "./LanguageSwitcher.scss";

class LanguageSwitcher extends Component {
  state = { language: "en" };

  onLanguageChange = (event) => {
    this.props.selectLanguage(event.target.value);
  };

  render() {
    const languageLogo = require("../../assets/icon/language.png");

    return (
      <div className="LanguageSwitcher" name="language">
        <div>
          <img className="img-language" src={languageLogo} alt="language" />
          <select
            id="language-select"
            name="lanaguage-select"
            onChange={this.onLanguageChange}
            selected={this.props.selectedLanguage}
          >
            <option value="en">English</option>
            <option value="ja">日本語</option>
            <option value="cn">简体中文</option>
            <option value="hk">繁体中文</option>
            <option value="kr">한국어</option>
            <option value="th">ภาษาไทย</option>
            <option value="vi">Tiếng Việt</option>
          </select>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.selectedLanguage,
  };
};

export default connect(mapStateToProps, { selectLanguage })(LanguageSwitcher);
