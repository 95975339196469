import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

import Header from "../Header/Header";
import NavBar from "../NavBar/NavBar";

import "./Top.scss";
class Top extends Component {
  render() {
    const { brand, loc, lang } = this.props;

    const locPin = require("../../assets/icon/pin.png");
    let wineBottle = brand.imageUrl
      ? brand.imageUrl
      : require("../../assets/Images/placeholder.png");
    let wineNamePic = brand.logoImageUrl ? brand.logoImageUrl : "";
    let wineName = "Loading";
    let breweryName = "Loading";
    let breweryAddr = "Loading";
    let saleSlogan = "Loading";
    switch (lang) {
      case "en": {
        wineName = brand.id ? brand.enName : "Loading";
        breweryName = loc.id ? loc.enName : "Loading";
        breweryAddr = loc.id ? loc.enMainAddress : "Loading";
        saleSlogan = brand.id ? brand.enIntro : "Loading";
        break;
      }
      case "jp": {
        wineName = brand.id ? brand.jpName : "Loading";
        breweryName = loc.id ? loc.jpName : "Loading";
        breweryAddr = loc.id ? loc.jpMainAddress : "Loading";
        saleSlogan = brand.id ? brand.jpIntro : "Loading";
        break;
      }
      case "cn": {
        wineName = brand.id ? brand.cnName : "Loading";
        breweryName = loc.id ? loc.cnName : "Loading";
        breweryAddr = loc.id ? loc.cnMainAddress : "Loading";
        saleSlogan = brand.id ? brand.cnIntro : "Loading";
        break;
      }
      case "hk": {
        wineName = brand.id ? brand.hkName : "Loading";
        breweryName = loc.id ? loc.hkName : "Loading";
        breweryAddr = loc.id ? loc.hkMainAddress : "Loading";
        saleSlogan = brand.id ? brand.hkIntro : "Loading";
        break;
      }
      case "kr": {
        wineName = brand.id ? brand.krName : "Loading";
        breweryName = loc.id ? loc.krName : "Loading";
        breweryAddr = loc.id ? loc.krMainAddress : "Loading";
        saleSlogan = brand.id ? brand.krIntro : "Loading";
        break;
      }
      case "th": {
        wineName = brand.id ? brand.thName : "Loading";
        breweryName = loc.id ? loc.thName : "Loading";
        breweryAddr = loc.id ? loc.thMainAddress : "Loading";
        saleSlogan = brand.id ? brand.thIntro : "Loading";
        break;
      }
      case "vi": {
        wineName = brand.id ? brand.viName : "Loading";
        breweryName = loc.id ? loc.viName : "Loading";
        breweryAddr = loc.id ? loc.viMainAddress : "Loading";
        saleSlogan = brand.id ? brand.viIntro : "Loading";
        break;
      }
      default: {
        wineName = brand.id ? brand.name : "Loading";
        breweryName = loc.id ? loc.name : "Loading";
        breweryAddr = loc.id ? loc.mainAddress : "Loading";
        saleSlogan = brand.id ? brand.intro : "Loading";
        break;
      }
    }
    return (
      <React.Fragment>
        <NavBar brand={brand} />

        <Grid container justify="center" alignItems="center">
          <Header />
          <Grid item xs={12} className="px-0 img-background">
            <div className="item-box">
              <img
                className="img-bottle"
                src={wineBottle}
                alt="WineBottlePicture"
              />
            </div>
          </Grid>
          
          <Grid item xs={12}>
            <div className="item-box">
              <img className="img-logo" src={wineNamePic} alt="WineNamePic" />
            </div>
            <div className="item-box wine-name">
              <span>{wineName}</span>
            </div>
          </Grid>

          <Grid item xs={6} className="px-0">
            <div className="item-box">
              <h3 className="master-brewery">{breweryName}</h3>
            </div>
          </Grid>

          <Grid item xs={12} className="px-0">
            <div className="item-box">
              <img
                className="ic_pin text-box"
                src={locPin}
                alt="LocationPinIcon"
              />
              <span className="brewery-address text-box">{breweryAddr}</span>
            </div>
          </Grid>

          <Grid item xs={8} sm={8} md={8} lg={8} className="px-0 mt-4 mb-4">
            <div className="item-box">
              <p className="sales_blurbs gray slogan-box">{saleSlogan}</p>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Top;
