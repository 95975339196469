import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CssBaseLine from "@material-ui/core/CssBaseLine";
import { connect } from "react-redux";

import LanguageSwitcher from "./LanguageSwitcher/LanguageSwitcher";
import MainApp from "./MainApp";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import siteText from "../data/site_text.json";


import "./App.scss";

class App extends Component {
 

  render() {
    const lang = this.props.selectedLanguage;
    return (
      <BrowserRouter>
        <CssBaseLine>
          <Container maxWidth="md">
            <div className="main-container mt16">
              <LanguageSwitcher />
            </div>
            <div>
              <Switch>
                {/* <Route exact path={"/"} component={MainApp} /> */}
                <Route
                  exact
                  path={"/bc/policy"}
                  render={(props) => (
                    <PrivacyPolicy {...props} lang={lang} siteText={siteText} />
                  )}
                />
                <Route
                  exact
                  path={"/bc/:serialID"}
                  render={(props) => (
                    <MainApp {...props} lang={lang} siteText={siteText} />
                  )}
                />
                <Route
                  exact
                  path={"/"}
                  render={(props) => (
                    <MainApp {...props} lang={lang} siteText={siteText} />
                  )}
                />
              </Switch>
            </div>
          </Container>
        </CssBaseLine>
       
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.selectedLanguage,
  };
};

export default connect(mapStateToProps)(App);
