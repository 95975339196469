
// あと、LP向けのAPIは以下になります。（serialパラメータで、シリアル番号を指定します）
// https://asia-northeast1-sake-bc-v2-stg.cloudfunctions.net/brand?serial=10001 
 
// 以下に、ダッシュボードのログイン情報を記載しているので、そこでデータを埋めて頂けると助かります。
// https://docs.google.com/spreadsheets/d/15pSsv_Wv55XMvGseiysQGaLkgaZfOPMLvp8WanybTXk/edit?usp=sharing 
 
// ご確認よろしくお願い致します。

const apiInfor = {
    // ステージング： Uncomment to build + deploy to staging
    // apiUrl: "https://asia-northeast1-sake-bc-v2-stg.cloudfunctions.net/",
  
   //本番： Uncomment to build + deploy to production
    apiUrl: "https://asia-northeast1-sakeblockchain-dist-campaign.cloudfunctions.net/",
  
    //off chain API
    brandAPI: "brand?",
    
    //parameters:
    codeKey: "code=",
    apiKey: "",
    paramSerial: "serial=",

    //google Analytics api key
    // //STG:
    gaCode: "UA-164308488-1",
    //PRD
    // gaCode: "UA-143369775-3",
  
  };
  
  export default apiInfor;