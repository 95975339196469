import React, { Component } from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Footer from "../Footer/Footer";
import Header from "../Header/Header";

import "./PrivacyPolicy.scss";

class PrivacyPolicy extends Component {
  handleToMainPage = () => {
    const serial = this.props.detectedSerial;
    this.props.history.push(`/bc/${serial}`);
  };

  render() {
    const { lang, siteText } = this.props;
    let text = {};
    switch (lang) {
      case "en": {
        text = siteText.en;
        break;
      }
      case "jp": {
        text = siteText.jp;
        break;
      }
      case "cn": {
        text = siteText.cn;
        break;
      }
      case "hk": {
        text = siteText.hk;
        break;
      }
      case "kr": {
        text = siteText.kr;
        break;
      }
      case "th": {
        text = siteText.th;
        break;
      }
      case "vi": {
        text = siteText.vi;
        break;
      }
      default: {
        text = siteText.jp;
        break;
      }
    }
    const privacyTitle = text.privacyPolicyTitle;
    const privacyText = text.legalNotes;
    const privacyRef = text.legalNotesFurterInformation;
    const gaText = text.labelTermsOfService;
    const ggText = text.labelPrivacyTerms;
    const gaLink = text.linkTermsOfService;
    const ggLink = text.linkPrivacyTerms;
    const backIcon = require("../../assets/icon/back.png");

    return (
      <React.Fragment>
        <div className="main-container">
          <Grid item xs={1} sm={1} md={1}>
            <img
              className="back-icon"
              src={backIcon}
              alt="backIcon"
              onClick={this.handleToMainPage}
            />
          </Grid>
          <div className="main-part">
            <Grid container justify="center" alignItems="center">
              <Header />
              <Grid item xs={12}>
                <div className="text-center">
                  <h1>{privacyTitle}</h1>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="mt-4 mb-4 mx-4 policy-text-box text-left ">
                  <p>{privacyText}</p>
                  <p>{privacyRef}</p>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="mb-4 mx-4 text-left">
                  <Button href={gaLink} color="primary">
                    {gaText}
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="mb-4 mx-4  text-left">
                  <Button href={ggLink} color="primary">
                    {ggText}
                  </Button>
                </div>
              </Grid>
              <Footer text={text} />
            </Grid>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    detectedSerial: state.detectedSerial,
  };
};

export default connect(mapStateToProps,)(PrivacyPolicy);
